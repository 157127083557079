import Vue from "vue";
import VueRouter from "vue-router";
import firebase from "firebase";
import axios from "axios";

Vue.use(VueRouter);
const isLoggedIn = () => firebase.auth().currentUser;

const routes = [
  {
    path: "/",
    name: "LoginScreen",
    component: () =>
      import(/* webpackChunkName: "Home" */ "../views/login/LoginScreen.vue"),
    meta: {
      showHeader: true,
      title: "Postzegelcentrale | Dashboard ",
    },
  },

  {
    path: "/Dashboard",
    name: "Dashboard",
    component: () =>
      import(/* webpackChunkName: "Dashboard" */ "@/layouts/AdminLayout.vue"),
    meta: { requiresAuth: true, accessRights: ["MAKELAAR"] },
    children: [
      {
        path: "/Dashboard",
        name: "Dashboard",
        component: () =>
          import(
            /* webpackChunkame: "Dashboard" */ "@/views/app/dashboard/Dashboard.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          showFooter: false,
          title: " Postzegels | Dashboard ",
        },
      },

      {
        path: "/Handelaars",
        name: "Handelaars",
        component: () =>
          import(
            /* webpackChunkName: "Handelaars" */ "@/views/app/traders/Handelaars.vue"
          ),
        meta: {
          requiresAuth: true,

          accessRights: ["WERKNEMER"],

          title: "Postzegelcentrale | Handelaarsoverzicht ",
        },
      },
      {
        path: "/Handelaar/bewerken/:id",
        name: "SingleBehandelaar",
        component: () =>
          import(
            /* webpackChunkName: "Handelaars" */ "@/views/app/traders/SingleBehandelaar.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          title: "Postzegelcentrale | Handelaar bewerken ",
        },
      },
      // Klanten
      {
        path: "/Klanten",
        name: "Klanten",
        component: () =>
          import(
            /* webpackChunkName: "Handelaars" */ "@/views/app/customers/Klanten.vue"
          ),
        meta: {
          requiresAuth: true,

          accessRights: ["WERKNEMER"],

          title: "Postzegelcentrale | Handelaarsoverzicht ",
        },
      },
      {
        path: "/Klant/bewerken/:id",
        name: "SingleKlant",
        component: () =>
          import(
            /* webpackChunkName: "Handelaars" */ "@/views/app/customers/SingleKlant.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          title: "Postzegelcentrale | Handelaar bewerken ",
        },
      },

      {
        path: "/Account",
        name: "Account",
        component: () =>
          import(
            /* webpackChunkName: "Handelaars" */ "@/views/app/account/Account.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          title: "Postzegelcentrale | Handelaar bewerken ",
        },
      },
      //Kavels

      {
        path: "/Kavelsbeheren",
        name: "Kavelsbeheren",
        component: () =>
          import(
            /* webpackChunkName: "Handelaars" */ "@/views/app/kavels/Kavelsbeheren.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          title: "Kavelsbeheren | Kavels bewerken ",
        },
      },

      {
        path: "/KavelToevoegen",
        name: "KavelToevoegen",
        component: () =>
          import(
            /* webpackChunkName: "Handelaars" */ "@/views/app/kavels/KavelToevoegen.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          title: "KavelToevoegen | Kavel toevoegen ",
        },
      },

      {
        path: "/SingleKavel/:id",
        name: "SingleKavel",
        component: () =>
          import(
            /* webpackChunkName: "Handelaars" */ "@/views/app/kavels/SingleKavel.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          title: "Kavelsbeheren | Handelaar bewerken ",
        },
      },

      {
        path: "/Koopjes",
        name: "Koopjes",
        component: () =>
          import(
            /* webpackChunkName: "Handelaars" */ "@/views/app/sales/Koopjes.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          title: "Kavelsbeheren | Handelaar bewerken ",
        },
      },

      //Logs

      {
        path: "/BerichtenLog",
        name: "BerichtenLog",
        component: () =>
          import(
            /* webpackChunkName: "Handelaars" */ "@/views/app/logs/BerichtenLog.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          title: "Berichtenlogs | Handelaar bewerken ",
        },
      },

      {
        path: "/KlantenLog",
        name: "KlantenLog",
        component: () =>
          import(
            /* webpackChunkName: "Handelaars" */ "@/views/app/logs/KlantenLog.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          title: "Klantenlogs | Handelaar bewerken ",
        },
      },

      //Rapportage
      {
        path: "/Rapportage",
        name: "Rapportage",
        component: () =>
          import(
            /* webpackChunkName: "Handelaars" */ "@/views/app/reporting/Rapportage.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          title: "Postzegelcentrale | Handelaar bewerken ",
        },
      },

      {
        path: "/RaportTemplate",
        name: "RaportTemplate",
        component: () =>
          import(
            /* webpackChunkName: "Handelaars" */ "@/views/app/reporting/RaportTemplate.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          title: "Postzegelcentrale | RaportTemplate maken ",
        },
      },

      {
        path: "/Handelaarkostenrapport",
        name: "Handelaarkostenrapport",
        component: () =>
          import(
            /* webpackChunkName: "Handelaars" */ "@/views/app/reporting/Handelaarkostenrapport.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          title: "Handelaarkosten | Rapport ",
        },
      },
      {
        path: "/HandelaarkostenrapportTabel",
        name: "HandelaarkostenrapportTabel",
        component: () =>
          import(
            /* webpackChunkName: "Handelaars" */ "@/views/app/reporting/HandelaarkostenrapportTabel.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          title: "Handelaarkosten | Rapport ",
        },
      },

      {
        path: "/RapportageUitdraaien",
        name: "RapportageUitdraaien",
        component: () =>
          import(
            /* webpackChunkName: "Handelaars" */ "@/views/app/reporting/RapportageUitdraaien.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          title: "Postzegelcentrale | Handelaar bewerken ",
        },
      },

      {
        path: "/HandelarenRapportage",
        name: "HandelarenRapportage",
        component: () =>
          import(
            /* webpackChunkName: "Handelaars" */ "@/views/app/reporting/HandelarenRapportage.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          title: "Postzegelcentrale | Handelaar bewerken ",
        },
      },

      {
        path: "/WebshopRapportage",
        name: "WebshopRapportage",
        component: () =>
          import(
            /* webpackChunkName: "Handelaars" */ "@/views/app/reporting/WebshopRapportage.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          title: "Postzegelcentrale | Handelaar bewerken ",
        },
      },

      {
        path: "/Verzendkostenbeheren",
        name: "Verzendkostenbeheren",
        component: () =>
          import(
            /* webpackChunkName: "Handelaars" */ "@/views/app/shippingcost/Verzendkostenbeheren.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          title: "Postzegelcentrale | Verzendkostenbeheren ",
        },
      },

      {
        path: "/ruudRapportage",
        name: "ruudRapportage",
        component: () =>
          import(
            /* webpackChunkName: "Handelaars" */ "@/views/app/reporting/ruudRapportage.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          title: "Postzegelcentrale | Handelaar bewerken ",
        },
      },

      {
        path: "/Betalingenuit",
        name: "Betalingenuit",
        component: () =>
          import(
            /* webpackChunkName: "Handelaars" */ "@/views/app/bookkeeping/Betalingenuit.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          title: "Postzegelcentrale | Handelaar bewerken ",
        },
      },

      {
        path: "/Betalinginformatie/:id",
        name: "Betalinginformatie",
        component: () =>
          import(
            /* webpackChunkName: "Handelaars" */ "@/views/app/bookkeeping/Betalinginformatie.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          title: "Postzegelcentrale | Handelaar bewerken ",
        },
      },
      //handelaar betalingen in out
      {
        path: "/HandelaarBetalingenuit",
        name: "HandelaarBetalingenuit",
        component: () =>
          import(
            /* webpackChunkName: "Handelaars" */ "@/views/app/traderinout/HandelaarBetalingenuit.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          title: "Postzegelcentrale | Handelaar bewerken ",
        },
      },

      {
        path: "/HandelaarBetalinginformatie/:id",
        name: "HandelaarBetalinginformatie",
        component: () =>
          import(
            /* webpackChunkName: "Handelaars" */ "@/views/app/traderinout/HandelaarBetalinginformatie.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          title: "Postzegelcentrale | Handelaar betaling informatie ",
        },
      },
      {
        path: "/Kassa",
        name: "Kassa",
        component: () =>
          import(
            /* webpackChunkName: "Handelaars" */ "@/views/app/cashier/Kassa.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          title: "Postzegelcentrale | Handelaar bewerken ",
        },
      },

      {
        path: "/Instructies",
        name: "Instructies",
        component: () =>
          import(
            /* webpackChunkName: "Handelaars" */ "@/views/app/instructions/Instructies.vue"
          ),

        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          title: "Postzegelcentrale | Instructies",
        },
      },

      {
        path: "/InstructiesCategorie",
        name: "InstructiesCategorie",
        component: () =>
          import(
            /* webpackChunkName: "Handelaars" */ "@/views/app/instructions/InstructiesCategorie.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          title: "Postzegelcentrale | Handelaar bewerken ",
        },
      },

      {
        path: "/EbayInstellingen",
        name: "EbayInstellingen",
        component: () =>
          import(
            /* webpackChunkName: "Handelaars" */ "@/views/app/ebay/EbayInstellingen.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          title: "Postzegelcentrale | Handelaar bewerken ",
        },
      },

      {
        path: "/EbaySites",
        name: "EbaySites",
        component: () =>
          import(
            /* webpackChunkName: "Handelaars" */ "@/views/app/ebay/EbaySites.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          title: "Postzegelcentrale | Handelaar bewerken ",
        },
      },

      {
        path: "/EbayBestellingen",
        name: "EbayBestellingen",
        component: () =>
          import(
            /* webpackChunkName: "Handelaars" */ "@/views/app/ebay/EbayBestellingen.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          title: "Postzegelcentrale | Handelaar bewerken ",
        },
      },

      {
        path: "/Koppeling",
        name: "Koppeling",
        component: () =>
          import(
            /* webpackChunkName: "Handelaars" */ "@/views/app/instellingen/Koppeling.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          title: "Postzegelcentrale | Handelaar bewerken ",
        },
      },

      {
        path: "/Embcode",
        name: "Embcode",
        component: () =>
          import(
            /* webpackChunkName: "Handelaars" */ "@/views/app/instellingen/Embcode.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          title: "Postzegelcentrale | Handelaar bewerken ",
        },
      },

      {
        path: "/Status",
        name: "Status",
        component: () =>
          import(
            /* webpackChunkName: "Handelaars" */ "@/views/app/instellingen/Status.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          title: "Postzegelcentrale | Status bewerken ",
        },
      },

      {
        path: "/LandToevegen",
        name: "LandToevegen",
        component: () =>
          import(
            /* webpackChunkName: "Handelaars" */ "@/views/app/instellingen/LandToevegen.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          title: "LandToevegen | Status bewerken ",
        },
      },

      {
        path: "/Continenten",
        name: "Continenten",
        component: () =>
          import(
            /* webpackChunkName: "Handelaars" */ "@/views/app/instellingen/Continenten.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          title: "Continenten | Continenten bekijken ",
        },
      },

      {
        path: "/BetaalStatus",
        name: "BetaalStatus",
        component: () =>
          import(
            /* webpackChunkName: "Handelaars" */ "@/views/app/instellingen/BetaalStatus.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          title: "Betaalstatus | Status bewerken ",
        },
      },

      {
        path: "/OrderStatus",
        name: "OrderStatus",
        component: () =>
          import(
            /* webpackChunkName: "Handelaars" */ "@/views/app/instellingen/OrderStatus.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          title: "Orderstatus | Status bewerken ",
        },
      },

      {
        path: "/Inhoud",
        name: "Inhoud",
        component: () =>
          import(
            /* webpackChunkName: "Handelaars" */ "@/views/app/instellingen/Inhoud.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          title: "Postzegelcentrale | Handelaar bewerken ",
        },
      },

      //

      {
        path: "/WebshopBestellingen",
        name: "WebshopBestellingen",
        component: () =>
          import(
            /* webpackChunkName: "Handelaars" */ "@/views/app/webshop/WebshopBestellingen.vue"
          ),
        meta: {
          requiresAuth: true,

          accessRights: ["WERKNEMER"],

          title: "Postzegelcentrale | Handelaarsoverzicht ",
        },
      },

      {
        path: "/Kortingenbeheren",
        name: "Kortingenbeheren",
        component: () =>
          import(
            /* webpackChunkName: "Handelaars" */ "@/views/app/discount/Kortingenbeheren.vue"
          ),
        meta: {
          requiresAuth: true,

          accessRights: ["WERKNEMER"],

          title: "Postzegelcentrale | Kortingenbeheren ",
        },
      },
      {
        path: "/Nieuwsbrieven",
        name: "Nieuwsbrieven",
        component: () =>
          import(
            /* webpackChunkName: "Handelaars" */ "@/views/app/newsletters/Nieuwsbrieven.vue"
          ),
        meta: {
          requiresAuth: true,

          accessRights: ["WERKNEMER"],

          title: "Postzegelcentrale | Nieuwsbrieven ",
        },
      },
      {
        path: "/KavelsFolder",
        name: "KavelsFolder",
        component: () =>
          import(
            /* webpackChunkName: "Handelaars" */ "@/views/app/instellingen/KavelsFolder.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          title: "Postzegelcentrale | BestellingBekijken bekijken ",
        },
      },

      {
        path: "/Ebayrapport",
        name: "Ebayrapport",
        component: () =>
          import(
            /* webpackChunkName: "Handelaars" */ "@/views/app/reporting/Ebayrapport.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          title: "Postzegelcentrale | BestellingBekijken bekijken ",
        },
      },

      {
        path: "/Bestelling/bekijken/:id",
        name: "BestellingBekijken",
        component: () =>
          import(
            /* webpackChunkName: "Handelaars" */ "@/views/app/webshop/BestellingBekijken.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          title: "Postzegelcentrale | BestellingBekijken bekijken ",
        },
      },
    ],
  },

  {
    path: "*",
    name: "/",
    component: () =>
      import(
        /* webpackChunkName: "NotFound" */ "../views/login/LoginScreen.vue"
      ),
    meta: { title: " Integratiepartners| Oeps pagina niet gevonden" },
  },
];

export async function setFirebaseToken() {
  const token = (await isLoggedIn())
    ? await firebase.auth().currentUser.getIdToken(true)
    : null;
  if (token) axios.defaults.headers.common["fireToken"] = token;
}

async function onAuthRequired(to, from, next) {
  document.title = to.meta.title || "Postzegels | Postcentrale Nederland";
  if (!to.meta.accessRights && to.path !== "/") return next("/");
  await setFirebaseToken();
  if (to.meta.requiresAuth) {
    if (!(await isLoggedIn())) {
      next({
        path: "/",
        query: { redirect: to.fullPath },
      });
    } else {
      const response = await axios.get(
        "https://server.postzegels.com/api/rest/v2/whoami"
      );
      const accessRight = response.data.accessRight;
      if (!to.meta.accessRights.includes(accessRight)) return next("/");
    }
  }
  next();
}

const router = new VueRouter({
  mode: "history",
  //Dit voor weer naar begin
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach(onAuthRequired);
export default router;
